import { Box, Button } from "@mui/material";
import ReactPlayer from "react-player";
import styled from "styled-components";

export const StyledBox = styled(Box)`
  width: 100vw;
  height: 100vh;
  background-image: url("/images/background_links.png");
  background-size: cover;
  background-position: center;

  @media (max-width: 480px) {
    background-position: unset;
  }
`;

export const StyledButton = styled(Button)`
  && {
    background-color: #ff7c06;
    color: black;
    text-transform: none;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-radius: 30px;
    width: 320px;
    height: 50px;
    color: black;

    &:hover {
      background-color: #2d3e91;
      color: white;
    }
  }
`;

export const StyledReactPlayer = styled(ReactPlayer)``;
