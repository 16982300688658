import { Stack, Box } from "@mui/material";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";
import Config from "data/config";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import { StyledBox, StyledButton, StyledReactPlayer } from "./styles";

export default function Links() {
  const whatsappLink = createLinkWhatsapp(
    Config.contact.whatsapp.number,
    Config.contact.whatsapp.message
  );
  const videoUrl = null;
  const links = [
    {
      link: "https://www.facebook.com/itoptelecom",
      target: "_blank",
      label: "Facebook",
      icon: <FacebookIcon />,
    },
    {
      link: "https://www.instagram.com/itoptelecom",
      target: "_blank",
      label: "Instagram",
      icon: <InstagramIcon />,
    },
    {
      link: "https://www.linkedin.com/company/itop-telecom1/",
      target: "_blank",
      label: "Linkedin",
      icon: <LinkedInIcon />,
    },
    {
      link: whatsappLink,
      target: "_blank",
      label: "WhatsApp",
      icon: <WhatsAppIcon />,
    },
    {
      link: "tel:08005918017",
      target: "_self",
      label: "Ligue Agora",
      icon: <CallIcon />,
    },
    {
      link: "https://apps.apple.com/br/app/itop-telecom/id1530283401",
      target: "_blank",
      label: "Baixe o App - App Store",
    },
    {
      link: "https://play.google.com/store/apps/details?id=br.com.mksolutions.mksac.itop&hl=pt_BR",
      target: "_blank",
      label: "Baixe o App - Play Store",
    },
  ];

  return (
    <StyledBox display={"flex"} flexDirection="column" alignItems={"center"}>
      <Box pt={videoUrl ? 5 : 8}>
        <img
          width="150px"
          height="150px"
          src="images/icons/itop/itop_new_icon.jpeg"
          alt="icon"
          style={{ borderRadius: "50%" }}
        />
      </Box>
      <Stack direction="column" spacing={2} py={4}>
        {links.map((it: any, index: number) => (
          <StyledButton
            startIcon={it.icon}
            onClick={() => openLink(it.link, it.target)}
            variant="contained"
            size="large"
            key={index}
          >
            <div style={{ width:  it.icon ? "125px" : '' }}>{it.label}</div>
          </StyledButton>
        ))}
      </Stack>
      {videoUrl && (
        <Box
          sx={{
            width: { xs: "90%", sm: "80%", md: "60%", lg: "50%" }, // Largura responsiva
            maxWidth: "600px", // Limita a largura máxima
            aspectRatio: "16/9", // Mantém a proporção do vídeo
            borderRadius: "15px", // Aplica o borderRadius
            overflow: "hidden", // Garante que o conteúdo não ultrapasse os limites
            margin: "0 auto", // Centraliza horizontalmente
          }}
        >
          <StyledReactPlayer
            controls
            url={videoUrl}
            width="100%"
            height="100%"
            style={{
              borderRadius: "inherit", // Herda o borderRadius do container
              overflow: "hidden", // Garante que o conteúdo respeite o formato arredondado
            }}
          />
        </Box>
      )}
    </StyledBox>
  );
}
