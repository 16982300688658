import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import Home from './Home'
import ReturnEquipment from './ReturnEquipment'
import InternalAffairs from "./InternalAffairs";
import WorkWhitUs from './WorkWhitUs'
import Location from './Location'
import Login from "./Login";
// import CreatePlan from './CreatePlan'
import MainLayout from "components/Layout/Main";
import ClearLayout from "components/Layout/Clear";
import Links from "./Links";

function Routers() {
  return (
    <TransitionGroup style={{ width: "100%", height: "100%" }}>
      <Routes>
        <Route element={<MainLayout />} >
          <Route path="/" element={<Home />} />
          <Route path="/cliente/devolucao-equipamentos" element={<ReturnEquipment />} />
          <Route path="/ouvidoria" element={<InternalAffairs />} />
          {/* <Route path="/monte-seu-plano" element={<CreatePlan />} /> */}
          <Route path="/trabalhe-conosco" element={<WorkWhitUs />} />
        </Route>
        <Route element={<ClearLayout />}> { /** Clear Layout */}
          <Route path="/localizacao" element={<Location />} />
          <Route path="/login" element={<Login />} />
          <Route path="/links" element={<Links />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </TransitionGroup>
  );
}

export default Routers;
