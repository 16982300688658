const appsPremiums: any = [
  {
    img: '/images/apps/premium/max_logo.jpg',
    name: "Max",
    description:
      "Max é um serviço de vídeo sob demanda global que traz diversão para a família toda com filmes, séries e muito mais.",
  },
  {
    img: '/images/apps/premium/hotgo-logo.jpg',
    name: "HOTGO",
    description: "O melhor conteúdo do universo adulto.",
  },
  {
    img: '/images/apps/premium/ritualfit.png',
    name: "Ritual Fit",
    description: "O aplicativo de treinamento pessoal na sua casa.",
  },
  {
    img: '/images/apps/premium/queima_diaria.jpg',
    name: "Queima Diária",
    description:
      "Você só precisa de alguns minutos por dia e poucas semanas para conquistar o corpo que sempre quis — e o melhor, sem sair de casa!",
  },
  {
    img: '/images/apps/premium/kapersky-logo.jpg',
    name: "Kaspersky",
    description:
      "Mais do que um antivírus. Obtenha tecnologia de segurança de ponta que protege tudo o que você faz online.",
  },
  {
    img: '/images/apps/premium/smart-content.png',
    name: "Smart Content",
    description: "Aprenda mais a qualquer hora e em qualquer lugar.",
  },
  {
    img: '/images/apps/premium/docway.jpg',
    name: "Docway",
    description:
      "Consultas médicas no conforto da sua casa com nossos profissionais qualificados.",
  },
];

export default appsPremiums;
