import { iPlanDetails } from "data/interfaces/plans";
import Config from "data/config";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";
import {
  Amount,
  AmountBox,
  AmountRecurrence,
  Benefit,
  Speed,
  StyledButton,
  StyledButton2,
  StyledCard,
  StyledList,
  StyledListItem,
  Title,
} from "./styles";
import { Box, Stack } from "@mui/material";
import AppListModal from "components/AppListModal";
import { useState } from "react";

type PropsCardPlan = {
  plan: iPlanDetails;
};

interface iSizes {
  icon: string;
  size: string;
}

export default function CardPlan({ plan }: PropsCardPlan) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formatAmount = (amount: number): string => {
    return amount.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const openBuyPlan = (plan: iPlanDetails) => {
    const message = `Olá acessei o website e tenho interesse em contratar:\n\nPlano: *${
      plan.title
    }*\nValor: *${formatAmount(plan.amount)}*`;

    const link = createLinkWhatsapp(
      Config.contact.whatsapp.number,
      encodeURIComponent(message)
    );

    openLink(link, "_blank");
  };

  const sizeIcon = (iconName: string) => {
    let defaultSize = "23px";
    const sizes: iSizes[] = [
      {
        icon: "mobile",
        size: "10px",
      },
      {
        icon: "fixo",
        size: "16px",
      },
      {
        icon: "contaoutravez",
        size: "30px",
      },
      {
        icon: "telecine",
        size: "35px",
      },
      {
        icon: "skeelo",
        size: "55px",
      },
      {
        icon: "globoplay",
        size: "55px",
      },
      {
        icon: "premier",
        size: "55px",
      },
      {
        icon: "deezer",
        size: "60px",
      },
      {
        icon: "max",
        size: "52px",
      },
      {
        icon: "ubook",
        size: "40px",
      },
    ];
    const size = sizes.find((icon) => iconName.includes(icon.icon));

    return size?.size || defaultSize;
  };
  return (
    <>
      <AppListModal
        modalIsOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <StyledCard>
        <Title>{plan.title}</Title>
        <Speed>
          {plan.speed} {plan.speedType} de velocidade
        </Speed>
        <StyledList>
          {plan.benefits.map(
            (benefit, index) =>
              benefit.title !== "MAX" && (
                <StyledListItem key={index}>
                  <img
                    src={`/images/icons/plans/${benefit.icon}`}
                    alt="icon-linkedin"
                    width={sizeIcon(benefit.icon)}
                  />
                  <Benefit>{benefit.title}</Benefit>
                </StyledListItem>
              )
          )}

          {plan.benefits.find((element) => element.title === "MAX") ? (
            <>
              <Box pt={1} width="100%">
                <StyledButton2 onClick={() => setIsModalOpen(true)}>
                  Escolha algum aplicativo premium
                </StyledButton2>
              </Box>
              <Stack direction="row" justifyContent="center" spacing={1} pt={2}>
                <img
                  src="/images/apps/hbomax.jpg"
                  alt="icon"
                  width="40px"
                  height="40px"
                  style={{ borderRadius: "5px" }}
                />
                <img
                  src="/images/apps/fit.png"
                  alt="icon"
                  width="40px"
                  height="40px"
                  style={{ borderRadius: "5px" }}
                />
                <img
                  src="/images/apps/kapersky.png"
                  alt="icon"
                  width="40px"
                  height="40px"
                  style={{ borderRadius: "5px" }}
                />
                <Box
                  onClick={() => setIsModalOpen(true)}
                  bgcolor="#ff7c06"
                  width="40px"
                  height="40px"
                  borderRadius="5px"
                  fontWeight="900"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ cursor: "pointer" }}
                >
                  +
                </Box>
              </Stack>
            </>
          ) : null}
        </StyledList>
        <AmountBox>
          <Amount>{formatAmount(plan.amount)}</Amount>
          <AmountRecurrence>{plan.recurrence}</AmountRecurrence>
        </AmountBox>
        <StyledButton
          variant="contained"
          size="large"
          onClick={() => openBuyPlan(plan)}
        >
          Quero contratar
        </StyledButton>
      </StyledCard>
    </>
  );
}
